import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store'
import { trackRouter } from 'vue-gtag-next'
import productboard from './productboard'
import cartboard from './cartboard'
import memberboard from './memberboard'
import eventlist from './eventlist'
import events from './events'

const routes = [
  {
    path: '/',
    component: () => import('../views/Frontend.vue'),
    children: [
      {
        path: '/',
        name: '首頁',
        component: () => import('../views/Home.vue'),
        meta: {
          title: 'papawash 首頁'
        }
      },
      {
        path: '/registerlogin',
        name: '登入/註冊',
        component: () => import('../views/RegisterLogin.vue'),
        meta: {
          title: 'papawash 登入/註冊'
        }
      },
      {
        path: '/forgetpwd',
        name: '忘記密碼',
        component: () => import('../views/ForgetPassword.vue'),
        meta: {
          title: 'papawash 忘記密碼'
        }
      },
      {
        path: '/changepwd/:key(.*)',
        name: '修改密碼',
        component: () => import('../views/ChangePassword.vue'),
        meta: {
          title: 'papawash 修改密碼'
        }
      },
      {
        path: '/aboutus',
        name: '關於我們',
        component: () => import('../views/AboutUs.vue'),
        meta: {
          title: 'papawash 關於我們'
        }
      },
      {
        path: '/papawashQA',
        name: 'Papawash Q&A',
        component: () => import('../views/PapawashQA.vue'),
        meta: {
          title: 'papawash Q&A'
        }
      },
      {
        path: '/privacy',
        name: '隱私權聲明',
        component: () => import('../views/Footer/Privacy.vue'),
        meta: {
          title: 'papawash 隱私權聲明'
        }
      },
      {
        path: '/TWCA',
        name: 'TWCA',
        component: () => import('../views/Footer/TWCA.vue'),
        meta: {
          title: 'papawash TWCA'
        }
      },
      {
        path: '/antideceive',
        name: '反詐騙提醒',
        component: () => import('../views/Footer/AntiDeceive.vue'),
        meta: {
          title: 'papawash 反詐騙提醒'
        }
      },
      {
        path: '/returnexchangepolicy',
        name: '退換貨須知',
        component: () => import('../views/Footer/ReturnExchangePolicy.vue'),
        meta: {
          title: 'papawash 退換貨須知'
        }
      },
      {
        path: '/copyright',
        name: '著作權聲明',
        component: () => import('../views/Footer/CopyRight.vue'),
        meta: {
          title: 'papawash 著作權聲明'
        }
      },
      /* 商品 */
      ...productboard,
      /* 購物車 結帳流程 */
      cartboard,
      /* 會員中心 */
      ...memberboard,
      /* 活動列表 */
      ...eventlist,
      /* 活動 */
      ...events
    ]
  },
  {
    // ?未對應的路由重新導向到首頁
    path: '/:pathMatch(.*)*',
    redirect: {
      name: '首頁'
    }
  }
]

const routeCfg = {
  history: createWebHistory(),
  scrollBehavior (to, from) {
    //* 手機登入，click購物車icon，登入後到購物車頁面
    // if (from.fullPath.match('/membermobilelogin')) {
    //   store.commit('refreshClickCart', true)
    // }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  }
}

routeCfg.routes = routes

const router = createRouter(routeCfg)

trackRouter(router, {
  useScreenview: true
})

export default router
