const productboard = [
  {
    path: '/product/:productId',
    // name: '商品明細',
    component: () => import('../views/Product/Product.vue')
  },
  {
    path: '/productlist',
    component: () => import('../views/Product/ProductList.vue'),
    meta: {
      title: 'papawash 全系列產品'
    }
  },
  {
    path: '/double11Event',
    component: () => import('../views/Product/DoubleProductList.vue'),
    meta: {
      title: 'papawash 雙11優惠活動'
    }
  }
  /* 商品搜尋 */
  // {
  //   path: 'productsearch/:keyword',
  //   name: '商品搜尋',
  //   component: () => import('../views/Product/ProductSearch.vue')
  // }
]

export default productboard
