const memberboard = [
  {
    path: '/memberorder',
    name: '會員訂單查詢',
    component: () => import('../views/Member/MemberOrder.vue'),
    meta: {
      title: 'papawash 會員訂單查詢'
    }
  },
  {
    path: '/contactus',
    name: '聯絡我們',
    component: () => import('../views/Member/ContactUs.vue'),
    meta: {
      title: 'papawash 聯絡我們'
    }
  },
  {
    path: '/memberfeedback',
    name: '我的優惠券-點數',
    component: () => import('../views/Member/MemberFeedback.vue'),
    meta: {
      title: 'papawash 我的優惠券 - 點數'
    }
  },
  {
    path: '/memberupdateinfo',
    name: '會員資料修改',
    component: () => import('../views/Member/MemberUpdateInfo.vue'),
    meta: {
      title: 'papawash 會員資料修改'
    }
  }
]
export default memberboard
