const cartboard = {
  path: 'checkoutboard',
  component: () => import('../views/Checkout/Checkoutboard.vue'),
  children: [
    {
      path: 'checkoutcart',
      name: '購物車',
      component: () => import('../views/Checkout/CheckoutCart.vue'),
      meta: {
        title: '購物車'
      }
    },
    {
      path: 'checkoutinfo',
      name: '填寫資料',
      component: () => import('../views/Checkout/CheckoutInfo.vue'),
      meta: {
        title: '填寫資料'
      }
    },
    {
      path: 'ordercomplete/:oid',
      name: '訂單確認',
      component: () => import('../views/Checkout/OrderComplete.vue'),
      meta: {
        title: '訂單確認'
      },
      alias: 'ordercomplete/:oid/:ts'
    },
    {
      path: 'ordercomplete/notfound',
      name: 'notfound',
      component: () => import('../views/Checkout/OrderNotFound.vue'),
      meta: {
        title: '訂單不存在'
      }
    },
    {
      path: 'birthdaycheckoutcart',
      name: '生日禮購物車',
      component: () => import('../views/EventList/BirthdayCheckoutCart.vue'),
      meta: {
        title: 'papawash 生日禮購物車'
      }
    },
    {
      path: '2023birthdaycheckoutcart',
      name: '2023生日禮購物車',
      component: () => import('../views/EventList/2023BirthdayCheckoutCart.vue'),
      meta: {
        title: '2023 papawash 生日禮購物車'
      }
    },
    {
      path: '2024birthdaycheckoutcart',
      name: '2024生日禮購物車',
      component: () => import('../views/EventList/2024BirthdayCheckoutCart.vue'),
      meta: {
        title: '2024 papawash 生日禮購物車'
      }
    },
    {
      path: 'cardbirthdaycheckoutcart',
      name: '聯邦卡友高消費客戶購物車',
      component: () => import('../views/Checkout/CardBirthdayCheckoutCart.vue'),
      meta: {
        title: 'papawash 聯邦卡友高消費客戶購物車'
      }
    }
  ]
}

export default cartboard
