import { createStore } from 'vuex'
// import axios from 'axios'

export default createStore({
  state: {
    cartNum: 0, //* header 購物車數量
    checkoutCart: {
      items: [],
      payInfo: {},
      activities: [],
      orderGifts: []
    }, //* 購物車資訊
    isLogin: false,
    checkInfo: [], //* 將訂單資訊暫存，避免返回頁面重新KEYIN
    isClickCart: false, //* 是否點擊購物車icon
    loginState: 'login',
    cartCalculate: {}
  },
  mutations: {
    getcartNum (state, allProductNum) {
      state.cartNum = allProductNum
    },
    getCheckoutCartInfo (state, CartInfo) {
      state.checkoutCart = CartInfo
    },
    changeLoginState (state, LoginState) {
      state.isLogin = LoginState
    },
    getCheckInfo (state, checkOrder) {
      state.checkInfo = checkOrder
    },
    refreshClickCart (state, clickCart) {
      state.isClickCart = clickCart
    },
    refreshloginState (state, clickLogin) {
      state.loginState = clickLogin
    },
    getCartCalculate (state, cart) {
      state.cartCalculate = cart
    }
  },
  actions: {
    loginSetCookie (context, payload) {
      const cookieToken = payload
      const time = new Date()
      const getTimer = time.getTime()
      time.setTime(getTimer + 1000 * (cookieToken.tokenAccessExpiredSeconds - 100))
      const utc = time.toUTCString()
      document.cookie = `PaPaLoginToken=${cookieToken.memberAccessToken}; expires=${utc};path=/`
      if (cookieToken.memberRefreshToken) {
        time.setTime(getTimer + 1000 * (cookieToken.tokenRefreshExpiredSeconds - 100))
        const utc2 = time.toUTCString()
        document.cookie = `PaPaRefreshToken=${cookieToken.memberRefreshToken};expires=${utc2};path=/`
      }
    },
    // ? 2種登出移除cookie
    logoutRemoveCookie () {
      document.cookie = 'PaPaLoginToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/'
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (RefreshToken) {
        document.cookie = 'PaPaRefreshToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/'
      }
    }
  }
})
